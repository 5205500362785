 /**
	* Updated July 2019 to support jquery 3.4.1
  * - bind/unbind is deprecated: https://jquery.com/upgrade-guide/3.0/#deprecated-bind-and-delegate
  * - .removeAttr() no longer sets properties to false: https://jquery.com/upgrade-guide/3.0/#attributes
  */

import { expandLocationFilterConditions } from './helpers/unified-filter-helper';
import { purify } from 'common/purify';

(function($) {
  var noFilterValue = {
    noFilter: true
  }; // sentinel value for blank filters

  /////////////////////////////////////
  // SETUP

  $(function() {
    var $filterConditionTemplate = $.getTemplate('filterCondition');
    $filterConditionTemplate.find('.autogeneratedCount').append($.tag(
      _.map(_.range(20), function(i) {
        return {
          tagName: 'option',
          value: i + 1,
          contents: i + 1
        };
      })
    ));
    $('#js-appended-templates').append($filterConditionTemplate);
  });

  /////////////////////////////////////
  // UTIL

  // cleans out filters down to a "minimal effective" state: essentially strips out
  // everything that is a NOOP on the actual filter operation.
  var cleanFilter = function(localRootCondition) {
    if (!cleanFilterRecurse(localRootCondition)) {
      return {};
    } else {
      return localRootCondition;
    }
  };
  var cleanFilterRecurse = function(condition) {
    delete condition.metadata;

    if (!_.isArray(condition.children) || (condition.children.length === 0)) {
      return false;
    }

    if ((condition.type == 'operator') && _.includes(['AND', 'OR'], condition.value)) {
      _.each(condition.children, function(child) {
        if (!cleanFilterRecurse(child)) {
          condition.children = _.without(condition.children, child);
        }
      });
    }

    return condition.children.length > 0;
  };
  // For the default dataset, remove any conditions that try to actually filter; those are disallowed
  var cleanDefaultFilter = function(localRootCondition) {
    if ($.isBlank(localRootCondition.value)) {
      return localRootCondition;
    }
    if (localRootCondition.value.toUpperCase() != 'AND' &&
      localRootCondition.value.toUpperCase() != 'OR') {
      return null;
    }
    localRootCondition.children = _.compact(_.map(localRootCondition.children, function(c) {
      return cleanDefaultFilter(c);
    }));
    return localRootCondition;
  };
  var trimEmptyFilters = function(localRootCondition) {
    if (_.isEmpty(localRootCondition)) {
      return null;
    }
    if (localRootCondition.type == 'operator' &&
      (localRootCondition.value == 'OR' || localRootCondition.value == 'AND')) {
      localRootCondition.children = _.compact(_.map(localRootCondition.children, trimEmptyFilters));
      if (_.isEmpty(localRootCondition.children)) {
        return null;
      }
      return localRootCondition;
    } else if (!_.some(localRootCondition.children, function(c) {
        return c.type == 'column';
      })) {
      return null;
    }
    return localRootCondition;
  };

  // helper to be sure we're fetching the right subcondition given a subcondition.
  // valid components: 'columnFieldName', 'subcolumn', 'value'
  // subindex can choose specific direct child rather than just taking the first
  var findConditionComponent = function(condition, component, subindex) {
    var child = condition.children[subindex || 0];
    if ($.isBlank(child)) {
      return false;
    }

    var lookingFor = {
      column: 'column',
      subcolumn: 'column',
      value: 'literal'
    };
    var returning = {
      subcolumn: 'value',
      value: 'value'
    };

    var result = [];
    _.each(child.children, function(subchild) {
      if (subchild.type == lookingFor[component]) {
        if (component == 'column') {
          result.push(subchild.columnFieldName || subchild.columnId);
        } else {
          result.push(subchild[returning[component]]);
        }
      }
    });

    if (result.length === 1) {
      return result[0];
    } else {
      return result;
    }
  };

  var getEditorComponentValue = function($editor) {
    var editor = $editor.data('unifiedFilter-editor');
    var value;
    if ($.isBlank(editor)) {
      value = $editor.data('unifiedFilter-editorValue');
    } else {
      if (!editor.isValid()) {
        return null;
      }
      value = editor.currentValue();
    }

    if (!$.isBlank(value) && !_.isNumber(value) && !_.isString(value) && !_.isBoolean(value) &&
      _.every(_.values(value), function(v) {
        return $.isBlank(v);
      })) {
      return null;
    }

    return value;
  };

  var getDefaultOperator = function(type) {
    return $.subKeyDefined(type, 'filterConditions.details.BETWEEN') ? 'between?' :
      $.subKeyDefined(type, 'filterConditions.details.EQUALS') ? 'EQUALS' :
      'blank?';
  };

  var getOperatorName = function(column, subcolumn, operator) {
    if (operator == 'blank?') {
      return 'is';
    }
    var type = column.renderType;
    if ($.subKeyDefined(type, 'subColumns.' + subcolumn)) {
      type = type.subColumns[subcolumn];
    }
    if (!$.subKeyDefined(type, 'filterConditions')) {
      return '';
    }
    return (type.filterConditions.details[operator] || {}).text || '';
  };

  var scrubFilterOperators = function(fc, newBackend) {
    // CONTAINS means different things on NBE vs OBE, the *_INSENSITIVE operator
    // is used by the NBE to replicate OBE behavior
    const sensitiveOps = ['CONTAINS', 'NOT_CONTAINS'];
    const insensitiveOps = ['CONTAINS_INSENSITIVE', 'NOT_CONTAINS_INSENSITIVE'];

    // we handle blank/notblank separately
    return _.compact(
      _.map(fc.orderedList, function(op) {
        // Only show OBE vs NBE option
        if ((newBackend && sensitiveOps.includes(op)) ||
          (!newBackend && insensitiveOps.includes(op)))
          return null;

        return (op == 'IS_BLANK' || op == 'IS_NOT_BLANK') ? null : {
            value: op,
            text: fc.details[op].text
          };
      })
    ).concat({
      value: 'blank?',
      text: $.t('core.filters.informal.is_blank')
    });
  };

  var makeValidCondition = function(condition) {
    if (condition.type == 'operator' && condition.value != 'OR' &&
      condition.value != 'AND') {
      condition.children = [$.extend(true, {}, condition)];
      condition.value = 'OR';
    }
  };

  var getFilterValue = function(value, column, metadata) {
    if (!$.isBlank(metadata.subcolumn) && (metadata.operator != 'blank?') &&
      $.isPlainObject(value)) {
      value = value[metadata.subcolumn];
    }
    var type = column.renderType;
    if ($.subKeyDefined(type, 'subColumns.' + metadata.subcolumn)) {
      type = type.subColumns[metadata.subcolumn];
    }
    if (_.isFunction(type.filterValue)) {
      value = type.filterValue(value);
    }
    return value;
  };

  // see if a condition is basically blank
  var hasNoValues = function(condition) {
    return (condition.metadata.operator == 'blank?') ||
      (((!_.isArray(condition.children)) ||
          (condition.children.length === 0)) &&
        (!_.isArray(condition.metadata.customValues) ||
          (condition.metadata.customValues.length === 0)));
  };

  // determine the default operator for a column given context
  var defaultOperatorForColumn = function(column, subcolumn) {
    var type = column.renderType;
    if ($.subKeyDefined(type, 'subColumns.' + subcolumn)) {
      type = type.subColumns[subcolumn];
    }
    var operator = getDefaultOperator(type);

    if (operator == 'between?') {
      // if we got a 'between?' value back on the filter, run some heuristics to determine
      // if equals is appropriate (eg low-cardinality); use between otherwise.
      // 5 is a somewhat arbitrary heuristic constant here
      if ($.subKeyDefined(column, 'cachedContents.top') &&
        ((column.cachedContents.top.length < 20) ||
          (_.filter(column.cachedContents.top, function(v) {
            return v.count > 1;
          }).length > 5))) {
        operator = 'EQUALS';
      } else {
        operator = 'BETWEEN';
      }
    }

    return operator;
  };

  // for what we need here, we don't care about the actual logarithm;
  // just the primary exponent and the leading digit.
  var fastLog = function(n) {
    var exp = 0;
    var isNegative = (n < 0);

    n = Math.abs(n);

    if ((n < 1) && (n > 0)) {
      // we're a negative exp
      while (n < 1) {
        n *= 10;
        exp--;
      }
    } else {
      // we're a nice normal number
      while (n >= 10) {
        n /= 10;
        exp++;
      }
    }
    return {
      leading: Math.floor(n),
      exp: exp,
      isNegative: isNegative
    };
  };

  // generate a Date that is rounded to a bounds
  var roundDate = function(date, resolution, direction) {
    // clone
    var result = new Date(date.getTime());

    var bottom = (direction == 'down');
    switch (resolution) {
      case 'year':
        result.setMonth(bottom ? 0 : 11);
        break;
      case 'month':
        if (bottom) {
          result.setDate(1);
        } else {
          // we're not as smart as Date, so let's ask it what it thinks
          // the last day of the month is, the way a baboon might.
          var expectedMonth = result.getMonth();
          result.setDate(31);

          if (result.getMonth() !== expectedMonth) {
            // well, we got it wrong. thankfully Date has set us straight.
            result.setDate(31 - result.getDate());
            result.setMonth(expectedMonth);
            // we don't have to check or set year here; december is 31 days.
          }
        }
        break;
      default: // case 'day'
        result.setHours(bottom ? 0 : 23);
        result.setMinutes(bottom ? 0 : 59);
        result.setSeconds(bottom ? 0 : 59);
        result.setMilliseconds(bottom ? 0 : 999);
    }

    return result;
  };

  var getRenderType = function(column, subColumn) {
    var type = column.renderType;
    if (!$.isBlank(subColumn)) {
      if ($.subKeyDefined(column.renderType, 'subColumns.' + subColumn)) {
        type = column.renderType.subColumns[subColumn];
      } else {
        type = null;
      }
    }
    return type;
  };

  /////////////////////////////////////
  // PLUGIN
  $.fn.unifiedFilter = function(options) {
    // note: startup tasks are at the bottom.

    var $pane = this;
    var isDirty = false; // keep track of whether we've ever deviated from saved
    var isEdit = false; // keep track of whether we're in edit mode

    // Pull some things out of options for easier access. Create unique IDs
    // for each dataset because with components & dataContext, you might
    // have multiple instances of the same dataset loaded
    var datasets = _.map(options.datasets, function(ds) {
      return {
        dataset: ds,
        ufID: ds.id + '_' + _.uniqueId()
      };
    });
    var dataset = datasets[0].dataset; // grab the first one; eg fsckLegacy only makes sense for one anyway

    var filterableColumns = options.filterableColumns; // this will change so save it off
    var rootCondition = $.extend(true, {}, options.rootCondition); // note: this may be null/undef
    var baseRootCondition;

    // Use a consistent ID to keep track of our particular query in each dataset
    var queryId = 'unifiedFilter' + _.uniqueId();
    var queryOwned = false;

    /////////////////////////////////////
    // DATASET-SPECIFIC UTIL

    // check to make sure we can render the thing; make minor corrections if possible
    var fsckLegacyV1 = function(localRootCondition) {
      var compatible = true;

      if (localRootCondition.type != 'operator' || !_.includes(['AND', 'OR'], localRootCondition.value)) {
        // we're something not a conjunction; we should be able to nest this
        // and everything will be okay
        localRootCondition.children = [$.extend({}, localRootCondition)];
        localRootCondition.type = 'operator';
        localRootCondition.value = 'OR';
      }

      // make sure we have children before _.each'ing it
      localRootCondition.children = localRootCondition.children || [];

      // we can handle anything at the top level (AND or OR)
      _.each(localRootCondition.children, function(condition, i) {
        if ((condition.type == 'operator') && _.includes(['AND', 'OR'], condition.value)) {
          // we can't handle 3 levels deep....
          if (_.some(condition.children || [], function(subcondition) {
              return _.includes(['AND', 'OR'], subcondition.value); /* BWWWWAAAAAAAAAAAAHHHHHHHHHHHHH */
            })) {
            var childCompatible = true;

            // ...unless it's multiple guidedFilter-generated betweens, in which case fix it
            _.each(condition.children || [], function(subcondition, j) {
              var checkBetween = fsckLegacyCheckBetween(subcondition);
              if ($.isPlainObject(checkBetween)) {
                condition.children[j] = checkBetween;
              } else {
                return childCompatible = (childCompatible && checkBetween);
              }
            });

            return compatible = (compatible && childCompatible);
          }

          // a nested OR can only contain the same operations on the same column
          var op, col;
          _.each(condition.children || [], function(subcondition) {
            if ((subcondition.type !== (op || subcondition.type)) ||
              (subcondition.columnFieldName !== (col || subcondition.columnFieldName))) {
              return compatible = false;
            }
            op = subcondition.type;
            col = subcondition.columnFieldName;
          });
        } else {
          // we're something not a conjunction; we should be able to nest this
          // and everything will be okay
          localRootCondition.children[i] = {
            type: 'operator',
            value: 'OR',
            children: [condition]
          };
        }
      });

      // ensure root node and all direct subchildren have accurate metadata objects
      if (_.isUndefined(localRootCondition.metadata)) {
        localRootCondition.metadata = {
          advanced: true,
          unifiedVersion: 1
        };
      }
      _.each(localRootCondition.children, function(child) {
        if (_.isUndefined(child.metadata)) {
          var column = dataset.columnForIdentifier(findConditionComponent(child, 'column'));
          var operator = child.children[0].value;

          if (_.includes(['IS_BLANK', 'IS_NOT_BLANK'], operator)) {
            operator = 'blank?';
          }
          child.metadata = {
            tableColumnId: column.tableColumnId,
            operator: operator
          };
          var subcolumn = (findConditionComponent(child, 'subcolumn') || '').toLowerCase();
          // Sanity check
          if (subcolumn && _.includes(_.keys(column.renderType.subColumns || {}), subcolumn)) {
            child.metadata.subcolumn = subcolumn;
          }
        }
      });

      fsckLegacyV2(localRootCondition);

      return compatible;
    };
    var fsckLegacyCheckBetween = function(condition) {
      if ((condition.value == 'AND') && (condition.children.length == 2)) {
        if (((condition.children[0].value == 'GREATER_THAN_OR_EQUALS') &&
            (condition.children[1].value == 'LESS_THAN_OR_EQUALS')) ||
          ((condition.children[0].value == 'LESS_THAN_OR_EQUALS') &&
            (condition.children[1].value == 'GREATER_THAN_OR_EQUALS'))) {
          return {
            type: 'operator',
            value: 'BETWEEN',
            children: [{
              columnFieldName: findConditionComponent(condition, 'column'),
              type: 'column'
            }, {
              value: findConditionComponent(condition, 'value', 0),
              type: 'literal'
            }, {
              value: findConditionComponent(condition, 'value', 1),
              type: 'literal'
            }]
          };
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    var fsckLegacyV2 = function(localRootCondition) {
      // assumes that we are already v1 compliant

      // the difference between v1 and v2 is fundamentally that any given
      // condition needs to know how to manipulate multiple distinct datasets
      // at any given time. this means that it has to be given a tableColumnId
      // per view-uid, which means that the tableColumnId field is now an obj

      _.each(localRootCondition.children, function(condition) {
        var newTCIDObj = {};
        newTCIDObj[dataset.publicationGroup] = condition.metadata.tableColumnId;
        condition.metadata.tableColumnId = newTCIDObj;
      });

      localRootCondition.metadata.unifiedVersion = 2;

      return true;
    };

    // EN-35160: We trim filters applied to hidden columns before
    // setting up the localRootCondition since we don't expose
    // full details of hidden columns to users without sufficient
    // permissions. Not removing them will attach these conditions to
    // the next soql query which will be invalid for an anonymous user.
    // Trimming of hidden column filters applies only when the
    // hidden column is not exposed.
    var trimHiddenColumnFilters = function(localRootCondition) {
      if (!_.isEmpty(localRootCondition)) {
        _.remove(localRootCondition.children, function(child) {
          return _.isUndefined(dataset.columnForIdentifier(findConditionComponent(child, 'column')));
        });
      }
      return localRootCondition;
    };

    var setUpRoot = function(localRootCondition, localDataset) {
      if (!_.isEmpty(localRootCondition)) {
        // great, we have a real filter to work with.

        // Handle the case when a namedFilter was stuck under an AND
        if (_.isEmpty(localRootCondition.metadata)) {
          var found = _.find(localRootCondition.children, function(c) {
            return _.isNumber((c.metadata || {}).unifiedVersion);
          });
          if (!$.isBlank(found)) {
            localRootCondition = found;
          }
        }

        // if we have something completely nonsensical, check v1 (which also checks v2)
        // otherwise, check v2
        if (((_.isUndefined(localRootCondition.metadata) ||
              _.isNaN(localRootCondition.metadata.unifiedVersion)) &&
            !fsckLegacyV1(localRootCondition)) ||
          ((localRootCondition.metadata.unifiedVersion < 2) && !fsckLegacyV2(localRootCondition))) {
          // this is some legacy or custom format that we're not capable of dealing with
          throw "Error: We're not currently capable of dealing with this filter.";
        }
      } else if ($.subKeyDefined(localDataset, 'metadata.filterCondition')) {
        // we might be looking at a default view with a filterCondition.
        localRootCondition = $.extend(true, {}, localDataset.metadata.filterCondition);

        // this must be at least a v1 unified filter. verify v2ness
        if (localRootCondition.metadata.unifiedVersion < 2) {
          fsckLegacyV2(localRootCondition);
        }
      } else {
        // we seriously can't find anything. init a new root.
        localRootCondition = {
          type: 'operator',
          value: 'AND',
          children: [],
          metadata: {
            advanced: true,
            unifiedVersion: 2
          }
        };
      }

      // the core server has a nasty habit of stripping empty []'s.
      localRootCondition.children = localRootCondition.children || [];

      // if there are no conditions at all, force to advanced
      if (localRootCondition.children.length == 0) {
        localRootCondition.metadata.advanced = true;
      }

      return localRootCondition;
    };

    /////////////////////////////////////
    // EXTERNAL BINDINGS

    this.on('columns_changed', function(event, args) {
      filterableColumns = args.columns;

      if ($.isBlank($pane)) {
        // we don't exist yet.
        return;
      }

      // update filters and remove ones that no longer apply
      $pane.find('.filterConditions .filterLink.columnName').each(function() {
        var $this = $(this);
        if (!_.includes(filterableColumns, $this.popupSelect_selectedItems()[0])) {
          removeFilter($this.closest('.filterCondition'), true);
        } else {
          $this.popupSelect_update(filterableColumns);
        }
      });
    });

    this.on('revert', function() {
      isDirty = false;

      if ($.isBlank($pane)) {
        // we don't exist yet.
        return;
      }

      $pane.find('.noFilterConditionsText').show();
      rootCondition = $.extend(true, {}, options.rootCondition);
      baseRootCondition = null;
      filterableColumns = options.filterableColumns;
      renderQueryFilters();
    });

    this.on('destroy', function() {
      // Un-apply dataset query
      _.each(datasets, function(ufDS) {
        var query = $.extend(true, {}, ufDS.dataset.query);
        if (queryOwned) {
          query.filterCondition = null;
        } else {
          // If not, find our corner of filter-space by name; set it up if necessary
          if (!$.isBlank(query.namedFilters)) {
            delete query.namedFilters[queryId];
          }
        }
        ufDS.dataset.update({
          query: query
        });
      });
      rootCondition = $.extend(true, {}, options.rootCondition);
      baseRootCondition = null;
      filterableColumns = options.filterableColumns;
    });

    /////////////////////////////////////
    // RENDER+EVENTS

    // check and render all the filters that are saved on the view
    var renderQueryFilters = function() {
      var showBaseFilters = !_.get(window, 'blist.feature_flags.force_use_of_modifying_lens_id_in_all_derived_views', false);

      if (showBaseFilters) {
        $pane.find('.baseFilterConditions').find('.filterCondition').remove();
      }

      $pane.find('.filterConditions').empty();

      if (_.isEmpty(rootCondition) && $.subKeyDefined(dataset, 'query.filterCondition')) {
        // extend this only if we have to and it exists (otherwise {} registers as !undefined)
        rootCondition = $.extend(true, {}, dataset.query.filterCondition);
        queryOwned = true;
      }

      // Consciously not handling namedFilters here, since we like to hide things there
      if (showBaseFilters && $.subKeyDefined(dataset, '_queryBase.query.filterCondition')) {
        baseRootCondition = trimEmptyFilters($.extend(true, {},
          dataset._queryBase.query.filterCondition));
        baseRootCondition = trimHiddenColumnFilters(baseRootCondition);
        baseRootCondition = setUpRoot(baseRootCondition, dataset._queryBase);
        rootCondition = blist.filter.generateSODA1({}, blist.filter.subtractQueries(
          Dataset.translateFilterColumnsToBase(
            Dataset.translateFilterCondition(rootCondition, dataset, false), dataset
          ).where,
          Dataset.translateFilterCondition(baseRootCondition, dataset._queryBase, false).where,
          dataset._queryBase
        ) || {});
      }

      rootCondition = setUpRoot(rootCondition, dataset);

      // are we advanced?
      $pane.toggleClass('advanced', !!rootCondition.metadata.advanced);
      $pane.toggleClass('notAdvanced', !rootCondition.metadata.advanced);
      $pane.find('.advancedStateLine').removeClass('hide').filter(
        rootCondition.metadata.advanced ? '.editModeAdvancedOffLine' : '.editModeAdvancedOnLine'
      ).addClass('hide');

      // set menu to current state
      $pane.find('.mainFilterOptionsMenu .matchAnyOrAll').removeClass('checked').
      filter(':has(>a[data-actionTarget=' + rootCondition.value + '])').addClass('checked');

      // now render each filter
      var hasRenderableBaseRootConditions = _.some((baseRootCondition || {}).children, function(cond) {
        // EN-16102: We don't expose the full details of hidden columns to users
        // without sufficient permissions, but the stored filters still leak the
        // existence of a hidden column if it's used in a filter condition. When
        // ONLY hidden columns are used in filters, we were showing the message
        // "With the following base filters" but then showing no filters, which
        // makes no sense. So we need to correlate filtered columns to available
        // columns in order to properly detect whether to show that section.
        var metadata = cond.metadata || {};
        if (_.isEmpty(metadata)) {
          return false;
        } else {
          return !!dataset._queryBase.columnForTCID(
            metadata.tableColumnId[dataset._queryBase.publicationGroup]
          );
        }
      });
      if (rootCondition.metadata.hideBase || !hasRenderableBaseRootConditions) {
        if (showBaseFilters) {
          $pane.find('.baseFilterConditions').addClass('hide');
        }
      } else {
        if (showBaseFilters) {
          $pane.find('.baseFilterConditions').removeClass('hide');
          _.each(baseRootCondition.children, renderBaseCondition);
          $pane.find('.baseFilterConditions .filterCondition:gt(0)').each(function() {
            $(this).before($.tag2({
              _: 'span',
              className: 'conditionJoin',
              contents: $.t('core.' + baseRootCondition.value.toLowerCase())
            }));
          });
        }
      }

      // If we have a bare condition, turn it into one that UF can handle
      if (rootCondition.type == 'operator' && rootCondition.value != 'AND' &&
        rootCondition.value != 'OR') {
        rootCondition = {
          type: 'operator',
          value: 'AND',
          metadata: {
            advanced: rootCondition.metadata.advanced,
            unifiedVersion: rootCondition.metadata.unifiedVersion
          },
          children: [rootCondition]
        };
      }

      _.each(rootCondition.children, renderCondition);

      // if we have nothing, show the beginner's message
      if (rootCondition.children.length == 0) {
        _.defer(function() {
          // hide and show don't work on this loop because the pane itself is hidden
          $pane.find('.initialFilterMode').show();
          $pane.find('.normalFilterMode').hide();
        });
      } else {
        _.defer(function() {
          // hide and show don't work on this loop because the pane itself is hidden
          $pane.find('.initialFilterMode').hide();
          $pane.find('.normalFilterMode').show();
        });
      }
    };

    var getColumnData = function(condition) {
      // EN-13300 - Fallback strategy for getting column metadata
      // We have to do this secondary check because in some cases the filter conditions
      // passed to this render function do not have tableColumnId properties assigned to
      // them. We can instead try to get the column in question by looking at the columnId
      // of any child filter condition. We arbitrarily take the first one.

      // if there are no children, fail
      if (_.isUndefined(condition.children)) {
        return;
      }

      var firstChildColumnId = _.chain(condition.children).
        map(function(conditionChild) { return conditionChild.columnId; }).
        reject(function(columnId) { return _.isUndefined(columnId); }).
        first().
        value();

      if (!_.isUndefined(firstChildColumnId)) {
        return dataset.columnForID(firstChildColumnId);
      }
    };

    var renderBaseCondition = function(condition) {
      var showBaseFilters = !_.get(window, 'blist.feature_flags.force_use_of_modifying_lens_id_in_all_derived_views', false);

      if (!showBaseFilters) {
        return;
      }

      var metadata = condition.metadata || {};
      // If we don't have metadata, then something we can't handle slipped in among
      // our valid items. Ignore it for now...
      if (_.isEmpty(metadata)) {
        return;
      }

      var column = dataset._queryBase.columnForTCID(
        metadata.tableColumnId[dataset._queryBase.publicationGroup]);

      // EN-13300: In some instances, the queryBase doesn't have the columnId information
      // With the check below, we try again to get the column data by looking at the condition itself
      if (_.isUndefined(column)) {
        column = getColumnData(condition);
      }

      // If the fallback strategy fails, then abort
      if (_.isUndefined(column)) {
        // someone must have changed the type on this or something. abort mission.
        //
        // EN-16102: "or something" can include the column being hidden, and thus
        // not readable to users with insufficient permissions.
        return;
      }

      makeValidCondition(condition);

      // render the main bits
      var $filter = $.renderTemplate('filterConditionStatic', {
        metadata: metadata,
        column: column
      }, {
        '.columnName': 'column.name!',
        '.columnInfo@title': 'column.description!',
        '.subcolumnName': function() {
          return (((column.renderType.subColumns || {})[metadata.subcolumn] || {}).title || '').toLowerCase();
        },
        '.subcolumnName@class+': function() {
          return (!$.isBlank(column.renderType.subColumns) &&
            _.size(column.renderType.subColumns) > 0) ? '' : 'hide';
        },
        '.operator': function() {
          return getOperatorName(column, metadata.subcolumn, metadata.operator);
        }
      });
      var filterUniqueId = 'filter_' + _.uniqueId();

      // hook up info tip
      var $info = $filter.find('.columnInfo');
      $info.toggleClass('hide', $.isBlank($info.attr('title')) || !metadata.showInfo);
      $info.socrataTip({
        message: purify($info.attr('title')) || '',
        killTitle: true
      });

      if (metadata.operator == 'blank?') {
        // special case these since they have no actual values
        if (_.some(condition.children || [], function(child) {
            return child.value == 'IS_BLANK';
          })) {
          addFilterLine({
              item: column.renderType.filterConditions.details.IS_BLANK.text.replace(/^is\s/, ''),
              data: 'IS_BLANK'
            }, column, condition, $filter,
            filterUniqueId, {
              textOnly: true,
              selected: true
            });
        } else if (_.some(condition.children || [], function(child) {
            return child.value == 'IS_NOT_BLANK';
          })) {
          addFilterLine({
              item: column.renderType.filterConditions.details.IS_NOT_BLANK.text.replace(/^is\s/, ''),
              data: 'IS_NOT_BLANK'
            }, column, condition, $filter,
            filterUniqueId, {
              textOnly: true,
              selected: true
            });
        }
      } else {
        // selected values
        _.each(condition.children || [], function(child, i) {
          var value = findConditionComponent(condition, 'value', i);

          addFilterLine({
            item: value
          }, column, condition, $filter, filterUniqueId, {
            selected: true
          });
        });
      }
      var $lines = $filter.find('.line');
      if ($lines.length > 1) {
        if ($lines.length > 2) {
          $lines.slice(0, $lines.length - 1).find('.lineValue').append(',');
        }
        $lines.eq($lines.length - 2).find('.lineValue').append(
          ' ' + $.t('core.' + condition.value.toLowerCase()));
      }
      $filter.find('input').attr('disabled', true);

      $pane.find('.baseFilterConditions').append($filter);
      $filter.slideDown();
    };

    // initial render and setup of filter condition
    var renderCondition = function(condition) {
      var column;
      var metadata = condition.metadata || {};
      // If we don't have metadata, then something we can't handle slipped in among
      // our valid items. Ignore it for now...
      if (_.isEmpty(metadata)) {
        return;
      }

      // TODO: need to actually merge the datasets (how?) rather than just taking the first blindly
      if (!_.isEmpty(metadata.tableColumnId) && !_.isNull(dataset.publicationGroup)) {
        column = dataset.columnForTCID(metadata.tableColumnId[dataset.publicationGroup]);
      }

      // EN-13300: In some instances, the queryBase doesn't have the columnId information
      // With the check below, we try again to get the column data by looking at the condition itself
      if (_.isUndefined(column)) {
        column = getColumnData(condition);
      }

      // If the fallback strategy fails, then abort
      if (_.isUndefined(column)) {
        // someone must have changed the type on this or something. abort mission.
        return;
      }

      makeValidCondition(condition);

      // If this a composite column without a subcolumn defined, force
      // it; we can't filter effectively without it
      if (!_.isEmpty(column.subColumnTypes) && $.isBlank(metadata.subcolumn)) {
        metadata.subcolumn = column.subColumnTypes[0];
      }

      // render the main bits
      var $filter = $.renderTemplate('filterCondition', {
        metadata: metadata,
        column: column
      }, {
        '.filterCondition@class+': function() {
          return (metadata.expanded === false) ? 'collapsed' : 'expanded';
        },
        '.columnName': 'column.name!',
        '.columnInfo@title': 'column.description!',
        '.subcolumnName': function() {
          return (((column.renderType.subColumns || {})[metadata.subcolumn] || {}).title || '').toLowerCase();
        },
        '.subcolumnName@class+': function() {
          return (!$.isBlank(column.renderType.subColumns) && _.size(column.renderType.subColumns) > 0) ? '' : 'hide';
        },
        '.operator': function() {
          return getOperatorName(column, metadata.subcolumn, metadata.operator);
        }
      });
      var filterUniqueId = 'filter_' + _.uniqueId();
      $filter.find('.autogeneratedCount').val(metadata.includeAuto || 5);

      if (_.includes(['BETWEEN', 'GREATER_THAN', 'GREATER_THAN_OR_EQUALS',
          'LESS_THAN', 'LESS_THAN_OR_EQUALS'
        ], metadata.operator)) {
        $filter.find('.autogeneratedProperties').html($.tag({
          tagName: 'p',
          contents: $.t('controls.filter.edit_default.auto_suggested')
        }));
      }

      // hook up events
      $filter.find('.filterRemoveButton').on('click', function(event) {
        event.preventDefault();

        removeFilter($filter);
        parseFilters();
      });
      $filter.find('.filterExpander').on('click', function(event) {
        event.preventDefault();

        if ($filter.hasClass('expanded')) {
          $filter.find('.filterValues').stop().slideUp();
          $filter.removeClass('expanded').addClass('collapsed');
        } else {
          $filter.find('.filterValues').stop().slideDown();
          $filter.removeClass('collapsed').addClass('expanded');
        }
      });
      $filter.find('.filterLink').on('click', function(event) {
        event.preventDefault();
      });
      $filter.find('.autogeneratedCount').on('change', function() {
        metadata.includeAuto = parseInt($(this).val());

        $filter.find('.autogenerated .line').remove();
        addAutogeneratedValues(condition, column, $filter, filterUniqueId, []);
      });

      // hook up info tip
      var $info = $filter.find('.columnInfo');
      $info.toggleClass('hide', $.isBlank($info.attr('title')) || !metadata.showInfo);
      $info.socrataTip({
        message: purify($info.attr('title')) || '',
        killTitle: true
      });

      // hook up popup menus
      $filter.find('.columnName').popupSelect({
        choices: filterableColumns,
        listContainerClass: 'popupColumnSelect',
        onShowCallback: function() {
          return $pane.hasClass('advanced');
        },
        prompt: $.t('controls.filter.actions.select_column'),
        renderer: function(col) {
          return [{
            tagName: 'span',
            'class': ['iconWrapper', col.renderTypeName],
            contents: {
              tagName: 'span',
              'class': 'blist-th-icon'
            }
          }, {
            tagName: 'span',
            'class': 'columnName',
            contents: $.htmlStrip(col.name)
          }];
        },
        selectCallback: function(newColumn) {
          if (newColumn == column) {
            // if the column hasn't changed don't do anything
            return true;
          }

          // make sure we deal with subcolumn in case we've set one
          if (!$.isBlank(newColumn.renderType.subColumns)) {
            metadata.subcolumn = newColumn.renderType.defaultFilterSubColumn;
          } else {
            delete metadata.subcolumn;
          }

          if (!$.subKeyDefined(getRenderType(newColumn, metadata.subcolumn),
              'filterConditions.details.' + metadata.operator)) {
            // the column they'd like to select doesn't support the operator they've selected
            if (!hasNoValues(condition) &&
              !confirm($.t('controls.filter.actions.confirm_modify'))) {
              return false;
            }
            condition.children = [];
            metadata.operator = defaultOperatorForColumn(newColumn, metadata.subcolumn);
            metadata.customValues = [];
          }

          metadata.tableColumnId[dataset.publicationGroup] = newColumn.tableColumnId;
          replaceFilter($filter, condition);

          return true;
        },
        selectedItems: column
      });

      if (blist.dataset.newBackend && _.includes(['CONTAINS', 'NOT_CONTAINS'], metadata.operator)) {
        // Map these to their case insensitive counterparts
        metadata.operator = metadata.operator + '_INSENSITIVE';
      }
      var renderType = getRenderType(column, metadata.subcolumn);
      var validOperators = scrubFilterOperators(renderType.filterConditions || {}, dataset.newBackend);
      $filter.find('.operator').popupSelect({
        choices: validOperators,
        listContainerClass: 'popupOperatorSelect',
        onShowCallback: function() {
          return $pane.hasClass('advanced');
        },
        prompt: $.t('controls.filter.actions.select_operation'),
        renderer: function(operator) {
          return operator.text;
        },
        selectCallback: function(newOperator) {
          if (newOperator == metadata.operator) {
            return true;
          }

          if ((newOperator.value == 'BETWEEN') ||
            (newOperator.value == 'blank?') ||
            (metadata.operator == 'BETWEEN') ||
            (metadata.operator == 'blank?')) {
            // when going to/from these types, we must blank the values (sorry)
            if (!hasNoValues(condition) &&
              !confirm($.t('controls.filter.actions.confirm_modify'))) {
              return false;
            }
            condition.children = [];
            metadata.customValues = [];
            metadata.operator = newOperator.value;

            replaceFilter($filter, condition);

            return true;
          } else {
            metadata.operator = newOperator.value;
            $filter.find('.operator').text(getOperatorName(column,
              metadata.subcolumn, newOperator.value));

            replaceFilter($filter, condition);

            return true;
          }
        },
        selectedItems: _.find(validOperators, function(operator) {
          return operator.value == metadata.operator;
        })
      });

      if (!$.isBlank(column.renderType.subColumns)) {
        $filter.find('.subcolumnName').popupSelect({
          choices: _.keys(column.renderType.subColumns).filter((subColumnName) => {
            // EN-28497 - Support filtering in grid view for Lat / Long for redirected dataset
            // EN-29433 - Prevent users from filtering on latitude or longitude
            //
            // We decided to just not allow people to filter on latitude or longitude, since it
            // appears that doing so never worked on the OBE in the first place. Although we decided
            // not to support this use case as a resolution to EN-28497, the user would still be given
            // the option to filter on the latitude or longitude subcolumn when adding a filter to a
            // location column in the grid view. Omittng those from the list of 'choices' passed to
            // $.fn.popupSelect will preven the UI from entering a state that suggests that we
            // permit filtering on the latitude or longitude subcolumns of an address column.
            return !_.includes(['latitude', 'longitude'], subColumnName);
          }),
          listContainerClass: 'popupSubcolumnSelect',
          onShowCallback: function() {
            return $pane.hasClass('advanced');
          },
          prompt: $.t('controls.filter.actions.select_subcolumn', {
            name: column.name
          }),
          renderer: function(subcolumn) {
            return ((column.renderType.subColumns[subcolumn] || {}).title || '').toLowerCase();
          },
          selectCallback: function(newSubcolumn) {
            if (newSubcolumn == metadata.subcolumn) {
              // nothing's changed here. ignore...
              return true;
            }

            if (!$.subKeyDefined(getRenderType(column, newSubcolumn),
                'filterConditions.details.' + metadata.operator)) {
              // the column they'd like to select doesn't support the operator they've selected
              if (!hasNoValues(condition) &&
                !confirm($.t('controls.filter.actions.confirm_modify'))) {
                return false;
              }
              condition.children = [];
              metadata.operator = defaultOperatorForColumn(column, newSubcolumn);
              metadata.customValues = [];
            }

            metadata.subcolumn = newSubcolumn;
            replaceFilter($filter, condition);

            return true;
          },
          selectedItems: metadata.subcolumn
        });

        // also set the class initially
        $filter.addClass(metadata.subcolumn);
      }

      // wire up options menu
      $filter.find('.filterOptionsMenu').menu({
        additionalDataKeys: ['actionTarget'],
        contents: [{
          text: $.t('controls.filter.menu.show_suggested'),
          href: '#toggleSuggestedValues',
          className: 'toggleAutogenerated' + (_.isUndefined(metadata.includeAuto) ? '' : ' checked')
        }, {
          divider: true
        }, {
          text: $.t('controls.filter.menu.select_one'),
          href: '#selectOne',
          actionTarget: 'one',
          className: 'selectOneOrMany' + (metadata.multiSelect === false ? ' checked' : '')
        }, {
          text: $.t('controls.filter.menu.select_many'),
          href: '#selectMany',
          actionTarget: 'many',
          className: 'selectOneOrMany' + (metadata.multiSelect !== false ? ' checked' : '')
        }, {
          divider: true
        }, {
          text: $.t('controls.filter.menu.match_any'),
          href: '#matchAny',
          actionTarget: 'OR',
          className: 'matchAnyOrAll' + (condition.value == 'OR' ? ' checked' : '')
        }, {
          text: $.t('controls.filter.menu.match_all'),
          href: '#matchAll',
          actionTarget: 'AND',
          className: 'matchAnyOrAll' + (condition.value == 'AND' ? ' checked' : '')
        }],
        menuButtonClass: 'filterOptionsMenuButton options',
        menuButtonContents: '',
        onOpen: function() {
          $filter.addClass('menuOpen');
        },
        onClose: function() {
          $filter.removeClass('menuOpen');
        }
      }).find('.menuEntry a').on('click', function(event) {
        event.preventDefault();
        var $this = $(this);
        var $entry = $this.closest('.menuEntry');

        if ($entry.hasClass('toggleAutogenerated')) {
          if (!_.isUndefined(metadata.includeAuto)) {
            $entry.removeClass('checked');
            $filter.find('.autogenerated .line').each(function() {
              var $line = $(this);
              if (!$line.find('.filterLineToggle').is(':checked')) {
                $line.remove();
              }
            });

            delete metadata.includeAuto;
            parseFilters();
          } else {
            $entry.addClass('checked');
            metadata.includeAuto = 5;
            addAutogeneratedValues(condition, column, $filter, filterUniqueId, [],
              function(addedValues) {
                if (addedValues.length === 0) {
                  var $noAutoValuesMessage = $.tag({
                    tagName: 'div',
                    'class': 'noAutoValuesMessage',
                    contents: $.t('controls.filter.main.no_suggestions')
                  });

                  $noAutoValuesMessage.appendTo($filter);
                  $noAutoValuesMessage.slideDown(function() {
                    setTimeout(function() {
                      $noAutoValuesMessage.slideUp(function() {
                        $noAutoValuesMessage.remove();
                      });
                    }, 2000);
                  });
                }
              });
          }
        } else if ($entry.hasClass('selectOneOrMany')) {
          metadata.multiSelect = ($this.attr('data-actionTarget') === 'many');

          replaceFilter($filter, condition);
        } else if ($entry.hasClass('matchAnyOrAll')) {
          condition.value = $this.attr('data-actionTarget');

          $entry.siblings('.matchAnyOrAll').removeClass('checked');
          $entry.addClass('checked');

          parseFilters();
        }
      });

      // dump in values
      if (metadata.multiSelect === false && !metadata.forceValue) {
        // add in "no filter" line; it's a radioline
        addFilterLine(noFilterValue, column, condition, $filter, filterUniqueId, {
          selected: !_.isArray(condition.children) || (condition.children.length === 0)
        });
      }

      if (metadata.operator == 'blank?') {
        // special case these since they have no actual values
        var cachedContents = column.cachedContents || {};
        // EN-14004: The null/not-null counts coming back from core are not correct for
        // checkbox columns. Ideally we'd fix core, but for now we'll hide the counts
        // for checkbox columns.
        var shouldDisplayCounts = column.dataTypeName !== 'checkbox';
        addFilterLine({
          item: renderType.filterConditions.details.IS_BLANK.text.replace(/^is\s/, ''),
          data: 'IS_BLANK',
          count: shouldDisplayCounts ? cachedContents['null'] : undefined
        }, column, condition, $filter, filterUniqueId, {
          textOnly: true,
          selected: _.some(condition.children || [], function(child) {
            return child.value == 'IS_BLANK';
          })
        });
        addFilterLine({
          item: renderType.filterConditions.details.IS_NOT_BLANK.text.replace(/^is\s/, ''),
          data: 'IS_NOT_BLANK',
          count: shouldDisplayCounts ? cachedContents['non_null'] : undefined
        }, column, condition, $filter, filterUniqueId, {
          textOnly: true,
          selected: _.some(condition.children || [], function(child) {
            return child.value == 'IS_NOT_BLANK';
          })
        });
      } else {
        // selected values
        var usedValues = [];
        _.each(condition.children || [], function(child, i) {
          var value = findConditionComponent(condition, 'value', i);
          usedValues.push(value);

          var childMetadata = child.metadata || {};
          addFilterLine({
            item: value
          }, column, condition, $filter, filterUniqueId, {
            selected: ((metadata.multiSelect !== false) || (i === 0)),
            freeform: !!childMetadata.freeform
          });
        });

        var setInitialValues = _.after(!_.isUndefined(metadata.includeAuto) ? 2 : 1, function() {
          if (metadata.forceValue && _.isEmpty(condition.children)) {
            _.defer(function() {
              $.uniform.update($filter.find('.filterLineToggle:first').value(true));
              parseFilters();
            });
          }

          if (metadata.multiSelect !== false && _.isEmpty(condition.children) &&
            (metadata.selectAll || metadata.selectTop)) {
            var $lines = $filter.find('.filterLineToggle');
            var c = metadata.selectTop || $lines.length;
            _.defer(function() {
              $.uniform.update($filter.find('.filterLineToggle').slice(0, c).value(true));
              parseFilters();
            });
          }

          // Hide extra lines initially
          if (_.isNumber(metadata.visibleItems)) {
            var $filterLines = $filter.find('.filterValues .line');
            $filterLines.slice(metadata.visibleItems).addClass('hide');
            var $moreLessLink = $filter.find('.showMoreLess');
            var isHidden = false;
            if ($filter.find('.filterValues .line.hide').length > 0) {
              $moreLessLink.removeClass('hide');
              isHidden = true;
            }

            if (metadata.usePopup) {
              var $popupContents = $.tag({
                tagName: 'div',
                'class': 'filterPopupContents'
              });
              var curSection;
              var usedSections = [];
              _.each(_.sortBy($filterLines.clone().removeClass('hide'), function(fl) {
                return $(fl).text();
              }), function(fl) {
                var $fl = $(fl);
                var firstL = $fl.text()[0].toUpperCase();
                if (firstL != curSection) {
                  curSection = firstL;
                  usedSections.push(curSection);
                  $popupContents.append($.tag({
                    tagName: 'span',
                    'class': ['sectionHeader', 'dontend',
                      'section-' + curSection, 'section-All'
                    ],
                    contents: curSection
                  }));
                }
                $fl.addClass('dontsplit section-All section-' + curSection);
                $popupContents.append($fl);
              });
              $popupContents.find('.filterLineToggle').quickEach(function() {
                var $t = $(this);
                $t.attr('data-relatedid', $t.attr('id'));
                $t.attr('id', $t.attr('id') + '_popup');
                $t.closest('.line').find('label').attr('for', $t.attr('id'));
              });

              var adjustColumns = function($pContents) {
                $('body').append($pContents);
                var itemW = $pContents.children('.line').outerWidth(true);
                $pContents.width(itemW);
                var colH = Math.min($pContents.height(), $(window).height() * 0.6);
                $pContents.css({
                  height: 'auto',
                  'max-height': 'none'
                });
                var numCols = Math.min(Math.ceil($pContents.height() / colH),
                  Math.floor(($(window).width() * 0.6) / itemW));
                $pContents.width(numCols * itemW);
                $pContents.columnize({
                  columns: numCols,
                  lastNeverTallest: true
                });
                $pContents.height(Math.min(colH, $pContents.height()) + 10);
                $pContents.detach();
                return $pContents;
              };

              var $popup = $.tag({
                tagName: 'div',
                'class': ['filterPopupBlock', filterUniqueId],
                contents: {
                  tagName: 'div',
                  'class': 'filterMenu',
                  contents: [{
                    tagName: 'a',
                    href: '#All',
                    'class': 'active',
                    contents: 'All'
                  }].concat(_.map(usedSections, function(sec) {
                    return {
                      tagName: 'a',
                      href: '#' + sec,
                      contents: sec
                    };
                  }))
                }
              });
              $popup.append(adjustColumns($popupContents.clone()));

              $(document).off('.filterPopup_' + filterUniqueId);
              $(document).on('click.filterPopup_' + filterUniqueId,
                '.filterPopupBlock.' + filterUniqueId + ' .filterMenu a',
                function(e) {
                  e.preventDefault();
                  var $link = $(this).addClass('active');
                  $link.siblings().removeClass('active');
                  var $block = $link.closest('.filterPopupBlock');
                  $block.find('.filterPopupContents').remove();
                  var $pContents = $popupContents.clone();
                  $pContents.children().addClass('hide').
                  filter('.section-' + $.hashHref($link.attr('href'))).removeClass('hide');
                  $block.append(adjustColumns($pContents));
                  $moreLessLink.socrataTip().refreshSize();
                });

              $moreLessLink.data('popup', $popup.add($popupContents));
              $moreLessLink.on('click', function(e) {
                e.preventDefault();
              }).
              socrataTip({
                content: $popup,
                fill: '#444444',
                isSolo: true,
                stroke: '#444444',
                trigger: 'click',
                positions: 'auto',
                shrinkToFit: true,
                shownCallback: function(box) {
                  var $b = $(box);
                  $moreLessLink.data('popup', $b.add($popupContents));
                  $b.find('.filterLineToggle').
                  on('change click', function() {
                    var $t = $(this);
                    $.uniform.update(
                      $filterLines.find('#' + $t.attr('data-relatedid')).attr('checked', $t.attr('checked') == 'checked').trigger('change')
                    );
                  }).
                  quickEach(function() {
                    var $t = $(this);
                    var $l = $t.closest('.line');
                    $l.prepend($t);
                    $l.children('.uniform').remove();
                    $t.uniform();
                  });
                }
              });
            } else {
              if (isHidden) {
                $moreLessLink.on('click', function(e) {
                  e.preventDefault();
                  isHidden = !isHidden;
                  $moreLessLink.toggleClass('rightArrow', isHidden).toggleClass('upArrow', !isHidden).
                  find('span.moreLess').text(isHidden ? 'More' : 'Less');

                  var $cont = $filter.find('.autogenerated');
                  var oldHeight = $cont.height();
                  var $items = $filter.find('.filterValues .line').slice(metadata.visibleItems);
                  $items.toggleClass('hide', isHidden);
                  var newHeight = $cont.height();
                  if (isHidden) {
                    $items.removeClass('hide');
                  }
                  $cont.height(oldHeight).animate({
                      height: newHeight
                    },
                    function() {
                      if (isHidden) {
                        $items.addClass('hide');
                      }
                      $cont.height('');
                    });
                });
              }
            }
          }
        });

        // autogen values
        if (!_.isUndefined(metadata.includeAuto)) {
          addAutogeneratedValues(condition, column, $filter, filterUniqueId, usedValues,
            function(internallyUsedValues) {
              usedValues = usedValues.concat(internallyUsedValues);
              setInitialValues(internallyUsedValues);
            });
        }

        // custom values
        if (_.isArray(metadata.customValues)) {
          _.each(metadata.customValues, function(value) {
            // don't render dupes
            if (!_.includes(usedValues, value)) {
              addFilterLine({
                item: value
              }, column, condition, $filter, filterUniqueId);
            }
          });
        }

        // freeform line
        if (!metadata.restrictedValues) {
          addFilterLine('', column, condition, $filter, filterUniqueId, {
            freeform: true
          });
        }

        setInitialValues();
      }

      if (metadata.multiSelect !== false) {
        var $clear = $filter.find('.clearValues');
        $clear.on('click', function(e) {
          e.preventDefault();
          $.uniform.update($filter.find('.filterLineToggle').value(false));
          parseFilters();
        });
      }

      // data
      $filter.data('unifiedFilter-condition', condition);

      // ui
      $pane.find('.filterConditions').children('.noFilterConditionsText').hide().end().append($filter);
      $filter.slideDown();

      // EN-10572 - Different Filter Operators Available for OBE/NBE Text Columns
      try {
        var tableColumnIdObj = _.get(condition, 'metadata.tableColumnId', {});
        var tableColumnId = _.get(Object.values(tableColumnIdObj), '0', null);
        var thisDataset = (dataset) ? dataset : _.get(window, 'blist.dataset');
        var columnForThisFilterControl = thisDataset.columnForTCID(tableColumnId);

        if (columnForThisFilterControl.dataTypeName === 'text') {

          $filter.append(
            '<div class="text-column-filter-operator-notice">' +
              $.t('controls.filter.main.filter_operators_for_text_columns_may_differ') +
            '</div>'
          );
        }
      } catch (e) {
        // Just don't fail if any of this additional UI fails.
      }

      return $filter;
    };

    // remove a filter entirely
    var removeFilter = function($filter, skipParseFilters) {
      prepFilterRemoval($filter, function() {
        rootCondition.children = _.without(rootCondition.children,
          $filter.data('unifiedFilter-condition'));

        // Sometimes the filter loses its parent before it gets here
        if ($filter.parent().length > 0) {
          if ($filter.siblings().length === 1) {
            // this is the last filter.
            $pane.find('.noFilterConditionsText').show();
          }

          $filter.remove();
        }

        if (skipParseFilters !== true) {
          parseFilters();
        }
      });
    };

    // replace a filter box with a new one to reflect changes
    var replaceFilter = function($filter, condition) {
      prepFilterRemoval($filter, function() {
        $filter.replaceWith(renderCondition(condition));
        parseFilters();
      });
    };

    // core of both remove and replaceFilter
    var prepFilterRemoval = function($filter, callback) {
      _.defer(function() {
        $filter.find('.filterLink').each(function() {
          var tip = $(this).data('popupSelect-tip');
          if (!_.isUndefined(tip)) {
            tip.destroy();
          }
        });

        _.defer(callback);
      });
    };

    // add a single filter item to a condition
    var addFilterLine = function(valueObj, column, condition, $filter, filterUniqueId, _options) {
      var metadata = condition.metadata || {};
      if (_.isUndefined(_options)) {
        _options = {};
      }

      // add elems
      var $line = $.tag({
        tagName: 'div',
        'class': 'line clearfix'
      });

      var inputId = 'unifiedFilterInput_' + _.uniqueId();
      $line.append($.tag({
        tagName: 'input',
        type: (metadata.multiSelect === false) ? 'radio' : 'checkbox',
        id: inputId,
        name: filterUniqueId,
        'class': 'filterLineToggle'
      }));
      if (_options.selected === true) {
        $line.find('.filterLineToggle').attr('checked', true);
      }
      $line.find(':radio, :checkbox').uniform();

      if (_options.autogenerated !== true) {
        $line.append($.tag({
          tagName: 'a',
          'class': 'removeFilterLine advanced remove',
          'href': '#remove',
          contents: {
            tagName: 'span',
            'class': 'icon',
            contents: 'remove'
          }
        }));
      }

      if (_options.freeform) {
        var renderType = column.renderType;
        if ($.subKeyDefined(column.renderType, 'subColumns.' + metadata.subcolumn)) {
          renderType = column.renderType.subColumns[metadata.subcolumn];
        }

        // If we don't know about this operator, then bail
        if ($.isBlank(renderType.filterConditions) ||
          $.isBlank(renderType.filterConditions.details[metadata.operator])) {
          return;
        }

        var editorInt = renderType.filterConditions.details[metadata.operator].interfaceType;

        // dump in the appropriate number of editors
        _.times(renderType.filterConditions.details[metadata.operator].editorCount, function(i) {
          if (i > 0) {
            $line.append($.tag({
              tagName: 'span',
              contents: $.t('core.and'),
              'class': 'conjunction'
            }));
          }

          $line.append($.tag({
            tagName: 'div',
            'class': 'filterValueEditor'
          }));
        });

        if (!metadata.autocomplete) {
          $line.find('.filterValueEditor').each(function(i) {
            var $this = $(this);
            var item = _.isArray(valueObj.item) ?
              valueObj.item[i] : valueObj.item;
            var editorValue = getFilterValue(item, column, metadata);

            $this.data('unifiedFilter-editor',
              $this.blistEditor({
                type: renderType,
                editorInterface: editorInt,
                value: editorValue,
                row: null,
                format: column.format,
                customProperties: {
                  dropDownList: column.dropDownList,
                  baseUrl: column.baseUrl()
                }
              }));
          });

          // events
          $line.find('.filterValueEditor input').on('focus', function() {
            if ($line.nextAll().length === 0) {
              // this is the last freeform line and the user just selected it; spawn new
              addFilterLine('', column, condition, $filter, filterUniqueId, {
                freeform: true
              });
            }
          });

          var eventName = 'edit_end';
          if (_.includes(['checkbox', 'stars'], column.renderTypeName)) {
            eventName = 'editor-change';
          }
          $line.find('.filterValueEditor').on(eventName, function() {
            var $this = $(this);
            var $lineToggle = $line.find('.filterLineToggle');

            if ((eventName == 'edit_end') && ($(document.activeElement).parents().index($this) < 0)) {
              // edit_end was called but we're actually elsewhere.
              return;
            }

            var $allLineToggles = $filter.find('.filterLineToggle');
            if ($.isBlank(getEditorComponentValue($this))) {
              var $nextLine = $this.closest('.line').next('.line');
              if ($nextLine.is(':last-child')) {
                $nextLine.remove();
              }

              if ($lineToggle.is(':checked')) {
                if (metadata.multiSelect === false) {
                  $allLineToggles.filter(':first').attr('checked', true);
                }
                $lineToggle.prop('checked', false);
              }
            } else {
              // don't check it if we're in edit mode, since edit mode shouldn't filter
              if ((!$lineToggle.is(':checked')) && !isEdit) {
                if (metadata.multiSelect === false) {
                  $allLineToggles.prop('checked', false);
                }
                $lineToggle.attr('checked', true);
              }
            }
            $.uniform.update($allLineToggles);
            parseFilters();
          });
        } else {
          $line.find('.filterValueEditor').each(function(i) {
            var $this = $(this);
            $this.addClass('autocompleteCombo');

            var editorValue = getFilterValue(_.isArray(valueObj.item) ?
              valueObj.item[i] : valueObj.item, column, metadata);

            $this.append($.tag({
              tagName: 'div',
              'class': 'wrapper',
              contents: [{
                tagName: 'input',
                type: 'text',
                'class': 'textInput',
                value: $.htmlEscape(editorValue)
              }, {
                tagName: 'a',
                href: '#choose',
                'class': 'dropdownChooser'
              }]
            }));
          });

          $line.find('.dropdownChooser').mousedown(function() {
            var $ti = $(this).siblings('.textInput');
            if (!$ti.is(':focus')) {
              _.defer(function() {
                $ti.focus();
              });
            }
          }).on('click', function(e) {
            e.preventDefault();
          });

          _.defer(function() {
            $line.find('.textInput').each(function() {
              var $ti = $(this);
              $ti.awesomecomplete({
                attachTo: $line.closest('.filterCondition'),
                forcePosition: true,
                showAll: true,
                skipBlankValues: true,
                suggestionListClass: 'autocompleteComboDropdown',
                renderFunction: function(dataItem) {
                  return $.tag({
                    tagName: 'div',
                    contents: [{
                      tagName: 'p',
                      'class': 'item',
                      contents: dataItem.item
                    }, {
                      tagName: 'p',
                      'class': 'count',
                      contents: '(' + dataItem.count + ')'
                    }]
                  }, true);
                },
                dataMethod: function(term, $f, dataCallback) {
                  column.getSummary(function(summary) {
                    var topItems = [];
                    _.each(summary, function(s, type) {
                      _.each(s.topFrequencies, function(t) {
                        var item = t.value;
                        if (!$.isBlank(column.subColumnTypes)) {
                          item = {};
                          item[type] = t.value;
                        }
                        topItems.push({
                          count: t.count,
                          item: item
                        });
                      });
                    });
                    dataCallback(topItems);
                  });
                },
                onComplete: function(data) {
                  if ($line.nextAll().length === 0) {
                    // this is the last autocomplete line
                    // and a value was selected; spawn new
                    addFilterLine('', column, condition, $filter, filterUniqueId, {
                      freeform: true
                    });
                  }
                  $ti.closest('.filterValueEditor').data('unifiedFilter-editorValue',
                    data.item);
                  if (!isEdit) {
                    $line.find('.filterLineToggle').attr('checked', true);
                  }

                  $.uniform.update($filter.find('.filterLineToggle'));
                  parseFilters();
                },
                valueFunction: function(dataItem) {
                  return dataItem.item || null;
                }
              });
            });
          });
        }
      } else if (valueObj == noFilterValue) {
        $line.append($.tag({
          tagName: 'label',
          'class': 'lineValue noFilterValue',
          'for': inputId,
          contents: $.t('controls.filter.actions.disable_filter')
        }));
        $line.data('unifiedFilter-value', noFilterValue);
      } else {
        // dump in rendered values
        $line.append($.tag({
          tagName: 'label',
          'class': 'lineValue',
          'for': inputId,
          contents: _.map($.arrayify(valueObj.item), function(valueObjPart, i) {
            var response = (i > 0) ? ' and ' : '';
            if ((_options.textOnly === true)) {
              response += valueObjPart.toString();
            } else {
              try {
                response += getRenderType(column, metadata.subcolumn).renderer(valueObjPart, column, false, true);
              } catch (ex) {
                response += valueObjPart.toString();
              }
            }
            return response;
          })
        }));

        // if subcolumn, count is invalid since server is counting entire value, not subvalue
        if (!_.isUndefined(valueObj.count) && _.isUndefined(metadata.subcolumn)) {
          $line.append($.tag({
            tagName: 'span',
            'class': 'lineCount',
            contents: ['(', valueObj.count, ')']
          }));
        }

        // data
        $line.data('unifiedFilter-value', !$.isBlank(valueObj.data) ? valueObj.data : valueObj.item);
      }

      // events
      $line.find('.filterLineToggle').on('change click', _.throttle(parseFilters, 0));

      $line.find('.removeFilterLine').on('click', function(event) {
        event.preventDefault();
        $line = $(this).closest('.line');

        // don't want to delete the last freeform line
        if (!$line.is(':last-child')) {
          $line.remove();
          parseFilters();
        }
      });

      // dom
      if (_options.autogenerated === true) {
        $filter.find('.filterValues .autogenerated').append($line);
      } else {
        $filter.find('.filterValues').append($line);
      }
    };

    // add a new condition
    var addNewCondition = function(column) {
      var newCondition = {
        children: [],
        type: 'operator',
        value: 'OR',
        metadata: {}
      };

      if ($.isBlank(column)) {
        // we weren't given a column to start with; pick one that's filterable--
        // ideally one that's not been filtered yet
        if (filterableColumns.length === 0) {
          // nothing to filter!
          return;
        }
        column = _.find(filterableColumns, function(col) {
          return !_.some(rootCondition.children, function(cond) {
            if (!_.isUndefined(cond.metadata.tableColumnId)) {
              return cond.metadata.tableColumnId[dataset.publicationGroup] == col.tableColumnId;
            }

            return false;
          });
        });
        if ($.isBlank(column)) {
          column = filterableColumns[0];
        }
      }
      newCondition.metadata.tableColumnId = newCondition.metadata.tableColumnId || {};
      newCondition.metadata.tableColumnId[dataset.publicationGroup] = column.tableColumnId;

      // do we have a composite column? if so get the most relevant subcolumn.
      if (!$.isBlank(column.renderType.subColumns)) {
        newCondition.metadata.subcolumn = column.renderType.defaultFilterSubColumn;
      }

      // okay, we have a column. now figure out what to filter it on.
      newCondition.metadata.operator = defaultOperatorForColumn(column,
        newCondition.metadata.subcolumn);

      rootCondition.children.push(newCondition);
      renderCondition(newCondition);
      return newCondition;
    };

    var aggregateCachedContents = function(metadata, column, callback) {
      var aggCC = {};

      var finalProcess = _.after(datasets.length, function() {
        if ($.subKeyDefined(aggCC, 'top')) {
          if (metadata.sortAlphabetically && $.subKeyDefined(column, 'metadata.displayOrder')) {
            var orderOpts = {};
            _.each(column.metadata.displayOrder, function(item, i) {
              orderOpts[item.orderItem] = i;
            });
            aggCC.top = aggCC.top.sort(function(a, b) {
              return a.item == b.item ? 0 :
                $.isBlank(orderOpts[a.item]) && $.isBlank(orderOpts[b.item]) ?
                (a.item > b.item ? 1 : -1) * (metadata.reverseSort ? -1 : 1) :
                $.isBlank(orderOpts[a.item]) ? 1 :
                $.isBlank(orderOpts[b.item]) ? -1 :
                (orderOpts[a.item] > orderOpts[b.item] ? 1 : -1) *
                (metadata.reverseSort ? -1 : 1);
            });
          } else {
            aggCC.top = _.sortBy(aggCC.top, function(v) {
              return _.isFunction(column.renderType.matchValue) ?
                column.renderType.matchValue(v.item, column) : v.item;
            });
            if (metadata.reverseSort) {
              aggCC.top.reverse();
            }
          }
          if (!metadata.sortAlphabetically) {
            aggCC.top = aggCC.top.sort(function(a, b) {
              return (b.count - a.count) * (metadata.reverseSort ? -1 : 1);
            });
          }
          if (metadata.useBottomItems && !$.isBlank(metadata.includeAuto)) {
            aggCC.top = aggCC.top.slice(-metadata.includeAuto);
          }
        }
        if (_.isFunction(callback)) {
          callback(aggCC);
        }
      });
      if (datasets.length === 0) {
        finalProcess();
      }

      var processColumn = function(cc) {
        if ($.subKeyDefined(cc, 'largest')) {
          if (!$.subKeyDefined(aggCC, 'largest')) {
            aggCC.largest = cc.largest;
          } else {
            aggCC.largest = Math.max(aggCC.largest, cc.largest);
          }
          if (!$.subKeyDefined(aggCC, 'smallest')) {
            aggCC.smallest = cc.smallest;
          } else {
            aggCC.smallest = Math.max(aggCC.smallest, cc.smallest);
          }
        }

        if ($.subKeyDefined(cc, 'top')) {
          if (!$.subKeyDefined(aggCC, 'top')) {
            aggCC.top = cc.top.slice();
          } else {
            _.each(cc.top, function(val) {
              var exist = _.find(aggCC.top, function(ti) {
                return ti.item == val.item;
              });
              if (!$.isBlank(exist)) {
                exist.count += val.count;
              } else {
                aggCC.top.push(val);
              }
            });
          }
        }

        finalProcess();
      };

      _.each(datasets, function(ufDS) {
        var ds = ufDS.dataset;
        var c = ds.columnForTCID(metadata.tableColumnId[ds.publicationGroup]);
        if (metadata.serverSummary || !$.subKeyDefined(c, 'cachedContents')) {
          c.getSummary(function(summary) {
            var topItems = [];
            _.each(summary, function(s, type) {
              _.each(s.topFrequencies, function(t) {
                var item = t.value;
                if (!$.isBlank(c.subColumnTypes)) {
                  item = {};
                  item[type] = t.value;
                }
                topItems.push({
                  count: t.count,
                  item: item
                });
              });
            });

            processColumn({
              top: topItems
            });
          }, metadata.includeAuto);
        } else {
          processColumn(c.cachedContents);
        }
      });
    };

    // add autogenerated values to a condition
    var addAutogeneratedValues = function(condition, column, $filter, filterUniqueId, usedValues,
      callback) {
      var metadata = condition.metadata || {};

      aggregateCachedContents(metadata, column, function(cachedContents) {
        var internallyUsedValues = [];

        if (_.includes(['EQUALS', 'NOT_EQUALS'], metadata.operator) &&
          $.subKeyDefined(cachedContents, 'top')) {
          var topCount = Math.min(metadata.includeAuto || 5, cachedContents.top.length);

          // iter through originals with count
          _.times(topCount, function(index) {
            var topValue = getFilterValue(cachedContents.top[index].item, column, metadata);

            if (_.isUndefined(topValue)) {
              return;
            }
            if (!_.includes(usedValues, topValue)) {
              addFilterLine({
                  item: topValue
                }, column, condition, $filter,
                filterUniqueId, {
                  autogenerated: true
                });

              internallyUsedValues.push(topValue);
            }
          });
        } else if (_.includes(['BETWEEN', 'LESS_THAN', 'LESS_THAN_OR_EQUALS',
            'GREATER_THAN', 'GREATER_THAN_OR_EQUALS'
          ], metadata.operator) &&
          $.subKeyDefined(cachedContents, 'largest')) {
          // assume smallest exists
          if (cachedContents.smallest == cachedContents.largest) {
            // really nothing to be between.
            return;
          }

          // whatever we decide to do, it'll come down to some range boundaries
          var rangeBoundaries = [];

          if (_.includes(['number', 'money', 'percent'], column.renderTypeName)) {
            // dealing with a numeric type here
            var range = cachedContents.largest - cachedContents.smallest;
            var lowerBound = fastLog(cachedContents.smallest);
            var upperBound = fastLog(cachedContents.largest);

            if (range > 5000) {
              // 5000 is somewhat arbitrary but informed by Benford's Law
              var i;
              // we're at a large enough range that we should just use a logarithmic scale

              // adjust to the outside of our ranges
              if (lowerBound.isNegative) {
                lowerBound.exp++;
              }
              if (!upperBound.isNegative) {
                upperBound.exp++;
              }

              // negative numbers
              if (lowerBound.isNegative) {
                for (i = lowerBound.exp; i >= (upperBound.isNegative ? upperBound.exp : 1); i--) {
                  rangeBoundaries.push(-Math.pow(10, i));
                }
              }
              // zero
              if (lowerBound.isNegative && !upperBound.isNegative) {
                rangeBoundaries.push(0);
              }
              // positive numbers
              if (!upperBound.isNegative) {
                for (i = (lowerBound.isNegative ? 1 : lowerBound.exp); i <= upperBound.exp; i++) {
                  rangeBoundaries.push(Math.pow(10, i));
                }
              }
            } else {
              // we're a pretty small range, so just divide somewhat evenly into 5ish buckets
              var adjustedLowerBound =
                (lowerBound.isNegative ? (lowerBound.leading + 1) : lowerBound.leading) *
                Math.pow(10, lowerBound.exp);

              rangeBoundaries.push(adjustedLowerBound);

              var interval = (cachedContents.largest - adjustedLowerBound) / metadata.includeAuto;

              if (interval > 20) {
                // we don't want to end up with ranges that look nonsensically
                // specific like 1,236,946.23, so just bump the interval up to
                // the nearest nice value
                var intervalLog = fastLog(interval);
                interval = (intervalLog.leading + 1) * Math.pow(10, intervalLog.exp);
              }

              var lastValue = adjustedLowerBound;
              while (lastValue < cachedContents.largest) {
                lastValue += interval;
                rangeBoundaries.push(lastValue);
              }
            }
          } else if (_.includes(['date', 'calendar_date'], column.renderTypeName)) {
            // dealing with a date thing here
            var start = cachedContents.smallest;
            var end = cachedContents.largest;

            // epoch ms if relevant
            if (_.isNumber(start)) {
              start *= 1000;
            }
            if (_.isNumber(end)) {
              end *= 1000;
            }
            var startDate = new Date(start);
            var endDate = new Date(end);

            var day = 86400000; // in ms

            range = (endDate.getTime() - startDate.getTime()) / day;

            var roundingFactor = '';
            interval = 0;

            if (range < 8) {
              roundingFactor = 'day';
              interval = 1;
            } else if (range < 32) { // days
              roundingFactor = 'day';
              interval = 7;
            } else if (range < 196) { // weeks
              roundingFactor = 'month';
              interval = 1;
            } else if (range < 367) { // months
              roundingFactor = 'month';
              interval = 3;
            } else if (range < 3670) { // quarters
              roundingFactor = 'year';
              interval = 1;
            } else { // years
              roundingFactor = 'year';
              interval = 10;
            } // decades

            startDate = roundDate(startDate, roundingFactor, 'down');

            rangeBoundaries.push(startDate.getTime());

            var dateMethods = {
              day: 'Date',
              month: 'Month',
              year: 'FullYear'
            };

            var incrementingDate = new Date(startDate.getTime());
            while (incrementingDate < endDate) {
              incrementingDate['set' + dateMethods[roundingFactor]](
                incrementingDate['get' + dateMethods[roundingFactor]]() + interval);
              rangeBoundaries.push(incrementingDate.getTime());
            }

            // remove the last thing and round tighter.
            rangeBoundaries.pop();
            rangeBoundaries.push(roundDate(endDate, roundingFactor, 'up').getTime());

            // massage the format given the type
            if (!_.isUndefined(column.renderType.stringFormat)) {
              rangeBoundaries = _.map(rangeBoundaries, function(d) {
                return new Date(d).toString(column.renderType.stringFormat);
              });
            } else {
              rangeBoundaries = _.map(rangeBoundaries, function(d) {
                return d / 1000;
              });
            }
          }

          // we have boundaries, now do some massaging depending on
          // what operator we have.
          if (_.includes(['LESS_THAN', 'LESS_THAN_OR_EQUALS'], metadata.operator)) {
            rangeBoundaries.shift(); // doesn't make sense to filter below min
          } else if (_.includes(['GREATER_THAN', 'GREATER_THAN_OR_EQUALS'], metadata.operator)) {
            rangeBoundaries.pop(); // doesn't make sense to filter above max
          }

          if (metadata.operator == 'BETWEEN') {
            while (rangeBoundaries.length > 1) {
              var value = [rangeBoundaries[0], rangeBoundaries[1]];
              internallyUsedValues.push(value);
              addFilterLine({
                  item: value
                }, column, condition, $filter,
                filterUniqueId, {
                  autogenerated: true
                });
              rangeBoundaries.shift();
            }
          } else {
            while (rangeBoundaries.length > 0) {
              value = rangeBoundaries[0];
              internallyUsedValues.push(value);
              addFilterLine({
                  item: value
                }, column, condition, $filter,
                filterUniqueId, {
                  autogenerated: true
                });
              rangeBoundaries.shift();
            }
          }
        }

        if (_.isFunction(callback)) {
          callback(internallyUsedValues);
        }
      });
    };

    var hookUpSidebarActions = function() {
      // advanced toggle
      $pane.find('.advancedStateLink').on('click', function(event) {
        event.preventDefault();
        var isAdvanced = $(this).hasClass('advancedOnLink');

        // update pane state
        $pane.toggleClass('advanced', isAdvanced || isEdit);
        $pane.toggleClass('notAdvanced', !isAdvanced && !isEdit);
        rootCondition.metadata.advanced = isAdvanced;

        // update edit mode messages
        $pane.find('.advancedStateLine').removeClass('hide').
        filter(isAdvanced ? '.editModeAdvancedOffLine' : '.editModeAdvancedOnLine').addClass('hide');

        parseFilters();
      });

      // main menu
      $pane.find('.mainFilterOptionsMenu').menu({
        additionalDataKeys: ['actionTarget'],
        contents: [{
          text: $.t('controls.filter.menu.match_any'),
          href: '#matchAny',
          actionTarget: 'OR',
          className: 'matchAnyOrAll'
        }, {
          text: $.t('controls.filter.menu.match_all'),
          href: '#matchAll',
          actionTarget: 'AND',
          className: 'matchAnyOrAll'
        }],
        menuButtonClass: 'filterOptionsMenuButton options',
        menuButtonContents: ''
      }).
      find('.menuEntry a').on('click', function(event) {
        event.preventDefault();
        var $this = $(this);
        var $entry = $this.closest('.menuEntry');

        rootCondition.value = $this.attr('data-actionTarget');

        $entry.siblings('.matchAnyOrAll').removeClass('checked');
        $entry.addClass('checked');

        parseFilters();
      });

      // add condition button
      $pane.find('.addFilterConditionButton').on('click', function(event) {
        event.preventDefault();
        addNewCondition();
        $pane.find('.initialFilterMode').hide();
        $pane.find('.normalFilterMode').show();
      });

      // publisher edit mode
      $pane.find('.editFilter').on('click', function(event) {
        event.preventDefault();

        if (dataset.temporary && !dataset.minorChange) {
          if (confirm($.t('controls.filter.edit_default.save_confirm'))) {
            // we need to first reset to a clean state, to avoid
            // saving changes we don't mean to
            dataset.reload();
          } else {
            return;
          }
        }

        $pane.find('.initialFilterMode').hide();
        $pane.find('.normalFilterMode').show();
        $pane.removeClass('notAdvanced').addClass('editMode advanced');
        $pane.find('.editModeMessage').effect('highlight', {}, 3000);
        $pane.find('.filterConditions input').filter(':radio, :checkbox').attr('disabled', true);
        isEdit = true;
      });

      $pane.find('.saveEditedFilter').on('click', function(event) {
        event.preventDefault();
        var $this = $(this);

        $this.addClass('disabled');
        $pane.find('.savingEditedFilterSpinner').css('display', 'inline-block');

        parseFilters(); // be absolutely sure we got everything

        // if we're a default view, move off the filterCondition from query to metadata
        if (dataset.type == 'blist') {
          dataset.update({
            metadata: $.extend({}, dataset.metadata, {
              filterCondition: cleanDefaultFilter(rootCondition)
            })
          });

          // just to be sure:
          var query = dataset.query;
          delete query.filterCondition;
          delete query.namedFilters;
          dataset.update({
            query: query
          });
        } else {
          dataset.update({
            query: $.extend({}, dataset.query, {
              filterCondition: rootCondition
            })
          });
        }

        dataset.save(function() {
          $pane.removeClass('editMode');
          isEdit = false;

          $this.removeClass('disabled');
          $pane.find('.savingEditedFilterSpinner').hide();
        });
      });

      $pane.find('.discardEditedFilter').on('click', function(event) {
        event.preventDefault();

        $pane.removeClass('editMode');
        isEdit = false;

        dataset.update({}); // hack to force temporary
        dataset.reload();
      });
    };

    /////////////////////////////////////
    // PARSING + DATASET

    // figure out what they entered and drop it into the dataset object
    var parseFilters = function() {
      if ($pane.parents('body').length < 1) {
        return;
      }
      var $filterConditions = $pane.find('.filterConditions .filterCondition');
      $filterConditions.removeClass('countInvalid');

      var datasetConditions = {};
      var datasetQueries = {};
      _.each(datasets, function(ufDS) {
        // OK, we need to play nice with existing queries...
        var query = $.extend(true, {}, ufDS.dataset.query);
        var curFC;
        var newRoot = {
          type: 'operator',
          children: [],
          metadata: {
            unifiedVersion: 2
          }
        };

        // If we own the whole query, then use it or set it up
        if (queryOwned) {
          // multiple left hand assignment is important here (and below), since curFC and query.filterCondition
          // _both_ need to be set to newRoot.
          curFC = query.filterCondition = query.filterCondition || newRoot;
        } else {
          // If not, find our corner of filter-space by name; set it up if necessary
          if ($.isBlank(query.namedFilters)) {
            query.namedFilters = {};
          }
          curFC = query.namedFilters[queryId] = query.namedFilters[queryId] || newRoot;
        }

        // If we're going to be saving this filter, make sure it is marked as something
        // we can handle
        if (!$.subKeyDefined(curFC, 'metadata.unifiedVersion')) {
          curFC.metadata = {
            unifiedVersion: 2
          };
        }

        curFC.children = [];
        curFC.value = rootCondition.value;

        // Whew; now that we're done setting everything up, store off the actual filter
        // we'll be working with; and also the top-level condition so we can properly
        // call update() later
        datasetConditions[ufDS.ufID] = curFC;
        datasetQueries[ufDS.ufID] = query;
      });

      $filterConditions.each(function() {
        var $filterCondition = $(this);
        var condition = $filterCondition.data('unifiedFilter-condition');
        var metadata = condition.metadata || {};

        var children = [];
        var column = dataset.columnForTCID(metadata.tableColumnId[dataset.publicationGroup]);
        var columnDefinition = {
          type: 'column',
          columnFieldName: column.fieldName
        };

        if (!_.isUndefined(metadata.subcolumn)) {
          columnDefinition.value = metadata.subcolumn;
        }

        var $lineToggles = $filterCondition.find('.filterLineToggle');

        // Update popup if it exists
        var $popup = $filterCondition.find('.showMoreLess').data('popup');
        // First, uncheck everything
        if (!$.isBlank($popup)) {
          $popup.find(':checkbox, :radio').attr('checked', false);
        }
        // Then check each matching item in the loop below
        $lineToggles.filter(':checked').each(function() {
          var $t = $(this);
          if (!$.isBlank($popup)) {
            $popup.find('[id^="' + $t.attr('id') + '"]').attr('checked', true);
          }

          var $line = $t.closest('.line');
          var value = $line.data('unifiedFilter-value');

          if (value == noFilterValue) {
            // they want no filter here
            return;
          } else if (metadata.operator == 'blank?') {
            // is_blank and is_not_blank are special
            children.push({
              type: 'operator',
              value: value,
              children: [columnDefinition]
            });
            return;
          } else if ($.isBlank(value)) {
            // must be a custom line
            value = [];
            $line.find('.filterValueEditor').each(function() {
              value.push(getEditorComponentValue($(this)));
            });

            if (_.some(value, $.isBlank)) {
              // they're not done editing yet — something is blank.
              return;
            }
          }

          const isFreeform = $line.find('.filterValueEditor').length > 0;
          const isRedirectedWithLocation = blist.dataset.newBackend && column.renderTypeName === 'location';

          // Check if this dataset is an OBE dataset that had Location columns that was redirected to the NBE
          // and reshape the filters accordinly
          if (value && isRedirectedWithLocation) {
            // EN-28497: need to fix lat/long filtering. They won't work with NBE POINT() for now.
            // Ignoring lat/long filter values for now on these redirected datasets.
            if (value[0] && (value[0].latitude || value[0].longitude)) {
              return;
            }

            const parsedLocationFilterVal = JSON.parse(value);
            const locationFilterConditions = expandLocationFilterConditions(
                parsedLocationFilterVal,
                metadata.operator,
                columnDefinition.columnFieldName,
                isFreeform
              );

            children.push(locationFilterConditions);

          // Otherwise do what we always used to do
          } else {
            children.push({
              type: 'operator',
              value: metadata.operator,
              metadata: {
                freeform: isFreeform
              },
              children: [columnDefinition].concat(_.map($.arrayify(value), function(v) {
                return {
                  type: 'literal',
                  value: getFilterValue(v, column, metadata)
                };
              }))
            });
          }
        });
        // Finally uniform update
        if (!$.isBlank($popup)) {
          $.uniform.update($popup.find(':checkbox, :radio'));
        }

        condition.metadata.customValues = [];
        $lineToggles.filter(':not(:checked)').each(function() {
          var $line = $(this).closest('.line');

          if (!$line.parents().hasClass('autogenerated')) {
            // if this is a custom value the user specified, add it to the metadata
            var value = $line.data('unifiedFilter-value');
            if (value == noFilterValue) {
              // ignore this line
              return;
            }

            if ($.isBlank(value)) {
              // must be a custom value line
              value = [];
              $line.find('.filterValueEditor').each(function() {
                value.push(getEditorComponentValue($(this)));
              });

              if (_.some(value, $.isBlank)) {
                // they're not done editing yet — something is blank.
                return;
              }
            }

            condition.metadata.customValues.push(value);
          }
        });

        if (_.isEmpty(condition.metadata.customValues)) {
          delete condition.metadata.customValues;
        }

        // populate the canonical condition
        condition.children = $.extend(true, [], children);

        // go through each dataset we have, update if necessary
        _.each(datasets, function(ufDS) {
          let ds = ufDS.dataset;
          // Adjust for this dataset
          columnDefinition.columnFieldName =
            ds.columnForTCID(metadata.tableColumnId[ds.publicationGroup]).fieldName;

          var dsCondition = $.extend(true, {}, condition);
          dsCondition.children = $.extend(true, [], children);
          datasetConditions[ufDS.ufID].children.push(dsCondition);
        });

        if (children.length > 0) {
          $filterCondition.siblings().addClass('countInvalid');
        }
        $filterCondition.find('.clearValues').toggleClass('hide', children.length < 1 ||
          metadata.multiSelect === false || metadata.clearOption !== true);
      });

      // now let's see how clean we are. if we're clean, no need to update the dataset.
      // TODO: can't really just blindly iterate through this with one isDirty. rethink.
      _.each(datasets, function(ufDS) {
        var ds = ufDS.dataset;
        if (isDirty ||
          !_.isEqual(cleanFilter($.extend(true, {}, ds.query.filterCondition)),
            cleanFilter($.extend(true, {}, rootCondition)))) {
          // add in the base root condition too.
          if (_.isArray(datasetConditions[ufDS.ufID].children) &&
            baseRootCondition && _.isArray(baseRootCondition.children)) {

            // unite the new queries group with the base queries group using "and"
            // clone so we don't get an infinite tree
            const children = [_.cloneDeep(datasetConditions[ufDS.ufID]), baseRootCondition];
            datasetConditions[ufDS.ufID].children = children;
            datasetConditions[ufDS.ufID].type = 'operator';
            datasetConditions[ufDS.ufID].value = 'AND';
          }

          // fire it off
          ds.update({
            query: datasetQueries[ufDS.ufID]
          });

          isDirty = true;
        }
      });
    };

    /////////////////////////////////////
    // GEARS IN MOTION

    dataset.getQueryBase(function() {
      hookUpSidebarActions();
      renderQueryFilters();
    });
  };
})(jQuery);
