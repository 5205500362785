import { map } from 'lodash';
import { assertIsNotNil, assertIsOneOfTypes, assertIsString } from 'common/assertions';

/*
  Location columns are being migrated to NBE point columns, but the API and UI still need to
  behave as if they support location types.

  For filters, this means modifying the View's queryJson to build an object that compares
  separate address fields (address, zip, state, city) using multiple string columns rather than
  using a single string-escaped json

  Something like:
    "children": [
      {
        "type": "operator",
        "value": "EQUALS",
        "children": [
            {
              "type": "column",
              "columnFieldName": "geocoded_column"
            },
            {
              "type": "literal",
              "value": "{\"address\":\"123 Main St.\",\"zip\":\"12345\",\"state\":\"NY\",\"city\":\"New York\"}"
            }
        ],
      }
    ],
    ...

  Needs to become something like:
    "children": [
      {
        "type": "operator",
        "value": "AND",
        "children": [
          {
            "type": "operator",
            "value": "EQUALS",
            "children": [
                {
                  "type": "column",
                  "columnFieldName": "geocoded_column_address",
                },
                {
                  "type": "literal",
                  "value": "123 Main St."
                }
            ],
          },
          {
            "type": "operator",
            "value": "EQUALS",
            "children": [
                {
                  "type": "column",
                  "columnFieldName": "geocoded_column_state",
                },
                {
                  "type": "literal",
                  "value": "NY"
                }
            ],
          },
      ...
*/

export const expandLocationFilterConditions = (addrObj, operatorVal, columnFieldName, isFreeform) => {
  assertIsNotNil(addrObj);
  assertIsOneOfTypes(addrObj, 'object');
  assertIsString(operatorVal);
  assertIsString(columnFieldName);
  assertIsOneOfTypes(isFreeform, 'boolean');

  // Join individual address components with an "AND" operator
  // i.e. match "123 Main St" AND "New York" AND "NY" AND "11234"
  const conditionWrapper = {
    type: 'operator',
    value: 'AND',
    children: []
  };

  const locationChildConditions = map(addrObj, (val, key) => {
    return {
      type: 'operator',
      value: operatorVal,
      metadata: {
        freeform: isFreeform
      },
      children: [{
        type: 'column',
        columnFieldName: `${columnFieldName}_${key}`
      }, {
        type: 'literal',
        value: val
      }]
    };
  });

  conditionWrapper.children = locationChildConditions;

  return conditionWrapper;
};
