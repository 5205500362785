(function($) {
  var userCanReadParentView = false;
  var filterableTypes = _.compact(_.map(blist.datatypes, function(t, n) {
    return !$.isBlank(t.filterConditions) || _.some(t.subColumns, function(st) {
      return !$.isBlank(st.filterConditions);
    }) ? n : null;
  }));
  var nbeFilterableTypes = _.compact(_.map(blist.datatypes, function(t, n) {
    return !t.disableNBEFilter && (!$.isBlank(t.filterConditions) || _.some(t.subColumns, function(st) {
      return !$.isBlank(st.filterConditions);
    })) ? n : null;
  }));

  $.Control.extend('pane_unifiedFilter', {
    getTitle: function() {
      return $.t('controls.filter.main.filter');
    },

    getSubtitle: function() {
      if (userCanReadParentView) {
        return $.t('controls.filter.main.filter_based_on_contents');
      } else {
        return $.t('screens.ds.grid_sidebar.filter.based_on_private_view');
      }
    },

    isAvailable: function() {
      // EN-29700 - Blue Pane should be disabled on SoQL-based view
      //
      // We need to disable the 'blue pane' controls when the view is defined
      // by a SoQL query, since we will disregard any configuration done via
      // the blue pane in favor of the SoQL query at <view>.queryString in
      // any case.
      if (!_.isUndefined(_.get(this, '_view.queryString'))) {
        return false;
      }

      return !$.isBlank(this._view) && this._view.visibleColumns.length > 0 && this._view.valid;
    },

    getDisabledSubtitle: function() {
      // EN-29700 - Blue Pane should be disabled on SoQL-based view
      //
      // We need to disable the 'blue pane' controls when the view is defined
      // by a SoQL query, since we will disregard any configuration done via
      // the blue pane in favor of the SoQL query at <view>.queryString in
      // any case.
      if (!_.isUndefined(_.get(this, '_view.queryString'))) {
        return $.t('controls.filter.main.view_is_soql_based_view');
      }

      return $.isBlank(this._view) ? 'No dataset is defined' : !this._view.valid ?
        $.t('controls.filter.main.view_must_be_valid') :
        $.t('controls.filter.main.view_has_no_columns');
    },

    setView: function(newView) {
      var cpObj = this,
        _super = cpObj._super;
      var handle = function(ds) {
        _super.apply(cpObj, [ds]);
        cpObj.reset();
        if (cpObj._pendingView) {
          if (blist.datasetPage && blist.datasetPage.sidebar) {
            blist.datasetPage.sidebar.updateEnabledSubPanes();
          }
          delete cpObj._pendingView;
        }
      };

      if ($.subKeyDefined(newView, 'displayFormat.viewDefinitions')) {
        cpObj._pendingView = true;
        if (newView.displayFormat.viewDefinitions[0].uid == 'self') {
          handle(newView);
        } else {
          Dataset.lookupFromViewId(newView.displayFormat.viewDefinitions[0].uid, handle);
        }
      } else {
        handle(newView);
      }
    },

    _getSections: function() {

      if (!userCanReadParentView) {
        return [];
      }

      return [{
        customContent: {
          template: 'filterPane',
          directive: {},
          data: {},
          callback: function($elem) {
            var cpObj = this;
            var canEditFilters = false;

            // EN-16102: This conditional used to reference blist.currentUser,
            // but apparently we instantiate that model asynchronously for...
            // reasons?... so this condition would improperly evaluate to false.
            // Checking for superadmin is not complicated, so this section has
            // been changed to bypass the model and just read the JSON directly.
            if (!_.isEmpty(blist.currentUserJson)) {
              canEditFilters = _.includes(blist.currentUserJson.flags, 'admin') ||
                blist.dataset.hasRight(blist.rights.view.UPDATE_VIEW);
            }

            var columns = cpObj._view.columnsForType(
              cpObj._view.newBackend ? nbeFilterableTypes : filterableTypes,
              canEditFilters
            ).filter(function(c) {
              return !_.includes(_.get(c, 'flags', []), 'hidden');
            });

            $elem.unifiedFilter({
              datasets: cpObj.settings.datasets || [cpObj._view],
              rootCondition: cpObj.settings.rootCondition,
              filterableColumns: columns
            });

            cpObj._view.unbind(null, null, cpObj);
            cpObj._view.bind('columns_changed', function() {
              $elem.trigger('columns_changed', {
                columns: columns
              });
            }, cpObj);

            cpObj._view.bind('clear_temporary', function() {
              $elem.trigger('revert');
            }, cpObj);
          },
          cleanupCallback: function($elem) {
            $elem.trigger('destroy');
          }
        }
      }];
    }
  }, {
    name: 'unifiedFilter',
    noReset: true
  }, 'controlPane');

  $(document).ready(function() {
    var showPane = _.get(window, 'socrata.gridView.sidebarPaneVisibility.filter.filter', false);
    var useSCGCDerivedViewsModel = _.get(window, 'socrata.featureFlags.force_use_of_modifying_lens_id_in_all_derived_views', false);

    if (showPane) {

      if (useSCGCDerivedViewsModel) {
        blist.dataset.getParentView(function(parDS) {
          userCanReadParentView = !$.isBlank(parDS);
          $.gridSidebar.registerConfig('filter.unifiedFilter', 'pane_unifiedFilter', 1, 'filter');
          blist.datasetPage.updateSidebarLinks();
        });
      } else {
        userCanReadParentView = true;
        $.gridSidebar.registerConfig('filter.unifiedFilter', 'pane_unifiedFilter', 1, 'filter');
      }
    }
  });
})(jQuery);
